<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties v-model="control">
        <template #padding></template>
        <!-- <InlineDimensionForm
          title="padding"
          v-model="padding"
          v-bind:lockables="[0, 1, 2, 3]"
          v-bind:labels="['top', 'right', 'bottom', 'left']"
        /> -->
      </ControlBaseProperties>
      <ControlSpecificPanel class="has-sub-panel" :control="control">
        <DetailFormChart v-model="control.synopticComponent.options" />
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import DetailFormChart, {
  titleStyleProperty
} from "@/components/control-sidebar/property-editors/detail-form-chart.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel";
import BaseControl from "./base-control";
import ControlBaseProperties from "./control-base-properties";
// import InlineDimensionForm from "./inline-dimension-form";

import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import { mapActions } from "vuex";

export default {
  name: "Chart",
  components: {
    DetailFormChart,
    ControlBaseProperties,
    ControlSpecificPanel,
    // InlineDimensionForm
  },
  extends: BaseControl,
  data() {
    return {
      control: null
    };
  },
  computed: {
    chartOptions() {
      return this.control.synopticComponent.options.chartOptions;
    },
    padding: {
      get() {
        return [
          this.chartOptions.grid.top,
          this.chartOptions.grid.right,
          this.chartOptions.grid.bottom,
          this.chartOptions.grid.left
        ];
      },
      set(val) {
        this.chartOptions.grid.top = val[0];
        this.chartOptions.grid.right = val[1];
        this.chartOptions.grid.bottom = val[2];
        this.chartOptions.grid.left = val[3];
      }
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: val,
          noMerge: true
        });
      }
    },
    controls: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val?.[0] && !isEqual(val[0], this.control)) {
          this.control = JSON.parse(JSON.stringify(val[0]));
        }
      }
    },
    "control.synopticComponent.style": {
      deep: true,
      handler(style) {
        let titleStyleProps = [
          "text-align",
          "color",
          "font-family",
          "font-size",
          "font-style",
          "font-weight"
        ];

        titleStyleProperty.set.call(this, pick(style, titleStyleProps));
      }
    }
  },
  methods: mapActions("synoptic", ["updateControl"])
};
</script>

<style></style>
